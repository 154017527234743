











































































































import {
  defineComponent,
  onMounted,
  nextTick,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";

export default defineComponent({
  setup(_, { root }) {
    const state = reactive({
      loading: false,
      success: false,
      error: false,
      loaded: false,
      dialog: false,
      currency: "",
      services: [],
      savedServices: [],
      totalLeftToPay: 0,
    });

    const model = reactive({
      name: "",
      services: [] as any,
      totalServicePrice: "",
    });

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`company/${root.$route.params.cid}`)
        .then(({ data: { company } }) => {
          model.name = company.name;
          state.savedServices = company.additives;
          model.totalServicePrice = company.totalServicePrice;
          state.currency = company.event.currency;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    const fetchTemplate = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`event/${root.$route.params.id}/company-additive-template`)
        .then(({ data: { templates } }) => {
          state.services = templates.map((t: any) => ({
            ...t,
            isEnabled: false,
            quantity: 0,
            total: 0,
            paid: 0,
            leftToPay: 0,
            paymentDate: null,
          }));
          const objOne = state.services.reduce((aggObj: any, item: any) => {
            aggObj[item.name] = item;
            return aggObj;
          }, {});

          const mergedObjOutput = state.savedServices.reduce(
            (aggObj: any, item: any) => {
              aggObj[item.name] = item;
              return aggObj;
            },
            objOne
          );

          model.services = Object.values(mergedObjOutput);
        })
        .catch((e) => console.log(e));
    };

    onMounted(fetchTemplate);
    onMounted(() => nextTick(fetchTemplate));

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        name: model.name,
        additives: model.services,
        totalServicePrice: model.totalServicePrice,
      };

      state.loading = true;

      axiosInstance
        .put(`company/${root.$route.params.cid}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.dialog = true;
          state.error = false;
        })
        .catch((error) => {
          state.error = error.response.status;
          state.dialog = true;
        })
        .finally(() => (state.loading = false));
    };

    watch(
      () => model.services,
      () =>
        (model.totalServicePrice = model.services.reduce(
          (prev: any, cur: any) => prev + cur.total,
          0
        )),
      { deep: true }
    );

    watch(
      () => model.services,
      () =>
        (state.totalLeftToPay = model.services.reduce(
          (prev: any, cur: any) => prev + cur.leftToPay,
          0
        )),
      { deep: true }
    );

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("layout.errors.paymentExists")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    return { state, model, onSubmit, getErrorMessage };
  },
});
